/* eslint-disable camelcase */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ALLOWED_USER_REGISTRATION_FIELDS, ALLOWED_PROFILE_FIELDS } from 'lib/constants';
import { AUTHENTICATED, UNAUTHENTICATED, UNINITIALIZED } from 'lib/myNewsConstants';
import { SCREENS, POST_LOGIN_SCREEN } from 'lib/loginFormConstants';
import { setCookie, getCookie, deleteCookie } from 'lib/cookies';
import { bedrockApi as api, decodeJWT } from './utils';

export const INITIAL_STATE = {
  user: {
    user_id: null,
    email: '',
    email_verified: false,
    last_name: '',
    first_name: '',
    birth_year: '',
    gender: '',
    address_line1: '',
    address_line2: null,
    state: '',
    zipcode: '',
    phone_number: '',
  },
  social: {
    idToken: '',
    firstName: '',
    platform: '',
    isNewUser: false,
  },
  access_token: null,
  error: '',
  loading: false,
  brand: '',
  showAuthenticationModal: false,
  screen: SCREENS.START,
  signInType: '',
  authenticationState: UNINITIALIZED,
  showGate: true,
};

/**
 * Creates the zustand store with initial state and actions for the Bedrock Registration service
 * @param {Function} set - The function to update the state.
 * @param {Function} get - The function to get the current state.
 * @returns {object} The store with state and actions.
 */
export const bedrockRegistrationStore = (set, get) => ({
  ...INITIAL_STATE,
  /**
   * Resets the data in the store to its initial state.
   * @returns {void}
   */
  reset: () => {
    set({ ...INITIAL_STATE }, false, 'reset');
    // Clear the cookies
    deleteCookie('access_token');
    deleteCookie('user_name');
    deleteCookie('SESSION');
    deleteCookie('bedrock_session');
  },

  /**
   * Sets the loading state in the store.
   * @param {boolean} loading - The loading state.
   * @returns {void}
   */
  setLoading: (loading) => set({ loading }, false, 'setLoading'),

  /**
   * Sets the authentication state in the store.
   * @param {string} authenticationState - The authentication state.
   * @returns {void}
   */
  setAuthenticationState: (authenticationState) => {
    if (authenticationState !== UNINITIALIZED) {
      get().setLoading(false);
    }

    // reset cookie value for user_name if NOT authenticated
    if (authenticationState === UNAUTHENTICATED) {
      get().reset();
    }

    if (authenticationState === AUTHENTICATED) {
      if (!POST_LOGIN_SCREEN.includes(get().screen)) {
        set({ showGate: false }, false, 'setAuthenticationState');
      }
    }
    set({ authenticationState }, false, 'setAuthenticationState');
  },

  /**
   * Checks if the token is expired.
   * @param {string} token - The JWT token.
   * @returns {boolean} The value of the `isExpired` flag.
   */
  isTokenExpired: (token = '') => {
    const payloadBase64 = token.split('.')[1];
    if (!payloadBase64) {
      return {};
    }
    try {
      const decodedJson = Buffer.from(payloadBase64, 'base64').toString();
      const decoded = JSON.parse(decodedJson);
      const { exp } = decoded;
      const tokenExpiry = exp * 1000;
      const isExpired = Date.now() >= tokenExpiry;
      return isExpired;
    } catch (error) {
      return true;
    }
  },

  /**
   * Sets the email in the store.
   * @param {string} email
   */
  setEmail: (email) => set({ user: { email } }, false, 'setEmail'),

  /**
   * Searches for a user by email and vertical/brand
   * @param {string} email
   * @param {VerticalType} brand
   * @returns {Promise<object>} The response from the API.
   */
  searchUserByEmail: async (email, brand) => {
    get().setLoading(true);
    set({ brand }, false, 'searchUserByEmail');
    try {
      const response = await api('/users/search/profile', {
        body: {
          email,
          brand,
        },
      });
      get().setLoading(false);
      return response;
    } catch (error) {
      return error;
    }
  },

  /**
   * Checks user password for login
   * @param {string} password
   * @returns {string} The status of the login.
   */
  userSignin: async (password) => {
    let status;

    get().setLoading(true);

    try {
      const response = await api('/users/signin', {
        body: {
          email: get().user.email,
          password,
          brand: 'today',
          remember_me: false,
        },
      });

      if (response?.success) {
        // Only update user properties defined in initial state
        const userData = Object.fromEntries(
          Object.keys(INITIAL_STATE.user)
            .map((field) => [field, response.data[field]])
            .filter(([, value]) => !!value),
        );

        // user has an email registered with an account, send them to the login screen
        set({
          user: { ...get().user, ...userData },
          access_token: response?.data?.access_token,
          signInType: 'login',
        }, false, 'userSignin');

        if (response?.data?.access_token) {
          get().restoreSession(response?.data?.access_token);
        }

        status = await get().getLoginSuccessScreen(response?.data);
      } else {
        // something went wrong with the api request, set an error
        set({ error: response?.error?.user_message }, false, 'userSigninError');
      }
    } catch (err) {
      // something went wrong with the call to the api, set an error
      set({ error: 'There was an error logging in' }, false, 'userSigninCallError');
      get().setLoading(false);
      return status;
    }
    get().setLoading(false);
    return status;
  },

  /**
   * Refreshes the user access token
   *  @returns {object} The user ID value
   */
  refreshUserToken: async () => {
    // check if user is already is logged in
    const access_token = getCookie('access_token');

    if (access_token) {
      const { sub: userId } = decodeJWT(access_token);

      if (userId) {
        // set user as authenticated when they have a valid token
        get().setAuthenticationState(AUTHENTICATED);
        // set user_id in store so it's available for MyNews API calls
        set({ user: { ...get().user, user_id: userId } }, false, 'refreshUserToken');

        // if access token is expired, refresh it
        if (get().isTokenExpired(access_token)) {
          try {
            await get().getUserToken(userId);
          } catch (err) {
            set({ error: err.message }, false, 'refreshUserTokenError');
          }
        }
      } else {
        // set as unauthenticated if no user id is found in token
        get().setAuthenticationState(UNAUTHENTICATED);
      }
    } else {
      // set as unauthenticated if no access token is found
      get().setAuthenticationState(UNAUTHENTICATED);
    }
  },

  /**
   * Retrieves a new user token from the API.
   * @param {string} userId - The ID of the user.
   * @returns {Promise<void>} Resolves when the token is successfully retrieved.
   */
  getUserToken: async (userId) => {
    try {
      const response = await api(`/users/${userId}/auth/token`, {
        method: 'POST',
        body: {
          bedrock_session: getCookie('bedrock_session'),
          scope: 'users:read_write',
        },
      });
      if (response?.success && response?.data?.access_token) {
        set({ access_token: response.data.access_token }, false, 'refreshUserToken');
        setCookie('access_token', response.data.access_token);
      } else {
        get().setAuthenticationState(UNAUTHENTICATED);
      }
    } catch (err) {
      set({ error: err.message }, false, 'refreshUserToken');
      get().setAuthenticationState(UNAUTHENTICATED);
      throw err;
    }
  },

  /**
   * Identifies a user with a one time codes
   * @param {string} oneTimeCode
   * @returns {string} The status of the one time code.
   */
  verifyOneTimeCode: async ({ oneTimeCode: one_time_code, vertical: brand }) => {
    let status;

    try {
      const response = await api('/users/otp/verify', {
        body: {
          email: get().user.email,
          brand,
          one_time_code,
        },
      });

      if (response?.success) {
        get().restoreSession(response?.data?.access_token);
        status = await get().getLoginSuccessScreen(response?.data);
      } else {
        // something went wrong with the api request, set an error
        set({ error: 'There was an error verifying the code' }, false, 'verifyOneTimeCode');
      }
    } catch (err) {
      set({ error: 'There was an error verifying the code' }, false, 'verifyOneTimeCode');
      return status;
    }
    return status;
  },

  /**
   * Sends the user a one time code for login verification
   */
  requestOneTimeCode: async () => {
    try {
      const response = await api('/users/otp/request', {
        body: {
          email: get().user.email,
          brand: get().brand,
        },
        headers: {
          Authorization: `Bearer ${get().clientToken}`,
        },
      });

      if (!response?.success) {
        // something went wrong with the api request, set an error
        set({ error: response?.error?.user_message }, false, 'oneTimeCode');
      }
    } catch (err) {
      set({ error: 'There was an error, please try again' }, false, 'oneTimeCode');
    }
  },

  /**
   * Determines the login success screen based on user data.
   * @param {object} data - The user data.
   * @returns {string} The screen to display.
   */
  getLoginSuccessScreen: async (data) => {
    let screen;
    if (data?.email_verified) {
      // prompt user to enter their name if they haven't already
      screen = data?.first_name ? SCREENS.LOGIN_SUCCESS : SCREENS.USER_NAME;
    } else {
      // prompt user to verify email if they haven't already
      screen = SCREENS.VERIFY_OTC;
    }
    return screen;
  },

  /**
   * Clears the user token from the store and the cookie
   * @returns {string} The status of the signout.
   */
  userSignout: async () => {
    get().setLoading(true);
    try {
      const response = await api(`/users/${get().user.user_id}/signout`, {
        body: {
          bedrock_session: getCookie('bedrock_session'),
        },
      });
      if (response?.success) {
        get().setAuthenticationState(UNAUTHENTICATED);
      } else {
        // something went wrong with the api request, set an error
        set({ error: response?.error?.user_message }, false, 'userSignout');
      }
    } catch (err) {
      set({ error: err.message }, false, 'userSignout');
    } finally {
      get().setLoading(false);
    }
  },

  /**
   * Fetches the user profile based on the user id
   * @param {string} access_token - The access token to restore the session
   * @returns {void}
   */
  restoreSession: async (access_token) => {
    get().setLoading(true);

    // Early return if no token is provided
    if (!access_token) {
      get().setAuthenticationState(UNAUTHENTICATED);
      get().setLoading(false);
      return;
    }

    // Get the user id form the token and fetch profile
    const { sub: user_id } = decodeJWT(access_token);

    // Early return if no user id is found in token
    if (!user_id) {
      get().setAuthenticationState(UNAUTHENTICATED);
      get().setLoading(false);
      return;
    }

    // Fetch the user profile
    try {
      const response = await api(`/users/${user_id}/profile`, {
        body: {
          access_token,
          bedrock_session: getCookie('bedrock_session'),
        },
      });

      if (response.success) {
        const userData = response.data;
        set({ user: userData }, false, 'restoreSession');
        get().setAuthenticationState(AUTHENTICATED);
        // set the user_name cookie
        if (userData.first_name) {
          setCookie('user_name', userData.first_name);
        } else {
          // clear if no first name is available
          deleteCookie('user_name');
        }
      } else {
        get().setAuthenticationState(UNAUTHENTICATED);
      }
    } catch (err) {
      get().setAuthenticationState(UNAUTHENTICATED);
    }
    get().setLoading(false);
  },

  /**
   * Handles user signin with Apple or Google
   * @param {object} socialUser - The social user object containing idToken, firstName, and isNewUser.
   * @param {VerticalType} vertical - The vertical.
   * @param {PlatformType} platform - Whether user is signing in with Google or Apple.
   * @returns {string} The status of the login.
   */
  socialLogin: async (socialUser, vertical, platform) => {
    let status;
    get().setLoading(true);

    // data returned from the social login response
    const {
      idToken,
      firstName,
      isNewUser,
    } = socialUser;

    if (isNewUser) {
      // direct new users to the registration screen before logging in
      set({ social: { idToken, firstName, platform }, brand: vertical }, false, 'socialLogin');
      get().setLoading(false);
      return SCREENS.SOCIAL_REGISTRATION;
    }

    try {
      const response = await api('/users/social/signin', {
        body: {
          id_token: idToken,
          brand: vertical,
          platform,
        },
      });

      if (response?.success) {
        // user successfully logs in with social login
        set({ user: response?.data?.access_token }, false, 'loginSuccess');
        get().restoreSession(response?.data?.access_token);
        if (!response?.data.first_name) {
          set({ signInType: 'login' });
          status = SCREENS.USER_NAME;
        } else status = SCREENS.LOGIN_SUCCESS;
      } else {
        // something went wrong with the api request, set an error
        set({ error: response?.error?.user_message }, false, 'loginError');
      }
    } catch (err) {
      set({ error: 'There was an error logging in' }, false, 'loginError');
      get().setLoading(false);
    }
    get().setLoading(false);
    return status;
  },

  /**
   * Registers a new user
   * @param {object} props - The user registration props.
   * @param {string} props.password - The user password.
   * @param {string} props.brand - The brand/vertical.
   * @param {string} props.first_name - The user first name.
   * @param {string} props.last_name - The user last name.
   * @param {string} [props.source] - The source of the registration.
   * @param {object} [props.profile] - Additional user profile data.
   * @param {string} props.email
   * @returns {string} The status of the registration.
   */
  userRegistration: async (props = {}) => {
    let status;
    get().setLoading(true);

    const body = Object.fromEntries(
      ALLOWED_USER_REGISTRATION_FIELDS
        .map((field) => [field, props[field]])
        .filter(([, value]) => value !== undefined),
    );

    try {
      const response = await api('/users/register', {
        body,
      });

      // Successful Registration
      if (response?.success) {
        get().setLoading(false);
        set({
          signInType: 'registration',
        }, false, 'registrationSuccess');

        status = SCREENS.VERIFY_OTC;
      } else {
        // something went wrong with the api request, set an error
        set({ error: response?.error?.user_message }, false, 'registrationError');
      }
    // Api error
    } catch (err) {
      set({ error: 'There was an error registering' }, false, 'registrationError');
    }
    get().setLoading(false);
    return status;
  },

  /**
   * Registers user via a social platform: google, apple etc.
   * @returns {string} The status of the registration.
   */
  userSocialRegistration: async () => {
    let status;

    try {
      const response = await api('/users/social/signin', {
        body: {
          id_token: get().social.idToken,
          brand: get().brand,
          platform: get().social.platform,
          first_name: get().social.firstName,
        },
      });

      if (response?.success) {
        set({ user: response?.data?.access_token }, false, 'userSocialRegistration');
        get().restoreSession(response?.data?.access_token);
        if (!response?.data?.first_name) {
          set({ signInType: 'registration' });
          status = SCREENS.USER_NAME;
        } else status = SCREENS.REGISTRATION_SUCCESS;
      } else {
        // something went wrong with the api request, set an error
        set({ error: response?.error?.user_message }, false, 'userSocialRegistration');
      }
    } catch (err) {
      set({ error: 'There was an error registering' }, false, 'userSocialRegistration');
      return status;
    }
    return status;
  },

  /**
  * Updates the user profile
  * @param {object} props - The user registration props.
  * @param {string} [props.first_name] - The user first name.
  * @param {string} [props.last_name] - The user last name.
  * @param {string} [props.birth_year] - The user birth year.
  * @param {string} [props.gender] - The user gender
  * @param {string} [props.address_line1] - The user address line 1
  * @param {string} [props.address_line2] - The user address line 2
  * @param {string} [props.state] - The user state
  * @param {string} [props.zipcode] - The user zipcode
  * @param {string} [props.phone_number] - The user phone number
  * @returns {string} The status of the login.
  */
  userProfileUpdate: async (props = {}) => {
    let status;
    get().setLoading(true);

    try {
      await get().getUserToken(get().user.user_id);
    } catch (err) {
      set({ error: err.message }, false, 'getUserTokenError');
      get().setLoading(false);
      return err.message;
    }

    const body = Object.fromEntries(
      Object.entries(props).filter(
        ([key, value]) => ALLOWED_PROFILE_FIELDS.includes(key) && value !== undefined,
      ),
    );

    try {
      const response = await api(`/users/${get().user.user_id}/profile`, {
        method: 'PATCH',
        headers: {
          'x-auth-token': get().access_token,
        },
        body: {
          ...body,
          access_token: get().access_token,
          bedrock_session: getCookie('bedrock_session'),
        },
      });

      if (response?.success) {
        get().restoreSession(get().access_token);
        status = get().signInType === 'registration'
          ? SCREENS.REGISTRATION_SUCCESS
          : SCREENS.LOGIN_SUCCESS;
      } else {
        // Set error message from API response if update fails.
        set({ error: response?.error?.user_message }, false, 'userProfileUpdateError');
      }
    } catch (err) {
      // Handle network or unexpected errors.
      set({ error: err.message }, false, 'userProfileUpdateError');
    } finally {
      get().setLoading(false);
    }
    return status;
  },

  /**
   * Sets the AuthenticationModal state in the store.
   * @param {boolean} showAuthenticationModal defaults to showing the modal
   */
  setAuthenticationModal: (showAuthenticationModal = true) => set({ showAuthenticationModal }, false, 'setAuthenticationModal'),

  /**
   * Sets the screen
   * @param {string} screen screen
   * @returns {void}
   */
  setScreen: (screen) => {
    set({ screen, error: '' }, false, 'setScreen');
    if (screen === SCREENS.LOGIN_SUCCESS) {
      set({ showGate: false }, false, 'setScreen');
    }
  },

  /**
   * Sets the error
   * @param {string} error error message
   * @returns {void}
   */
  setError: (error) => set({ error }, false, 'setError'),

});

/**
 * Enable the devtools in development mode only
 */
const isDevelop = process.env.NODE_ENV === 'development';

const devToolsProps = {
  name: 'useBedrockRegistration',
  anonymousActionType: 'action',
  serialize: true,
  // eslint-disable-next-line jsdoc/require-jsdoc
  actionSanitizer: (action) => ({
    ...action,
    type: `BedrockRegistration/${action.type}`,
  }),
};

// eslint-disable-next-line jsdoc/require-jsdoc
const middlewares = (f) => (isDevelop ? devtools(f, devToolsProps) : f);

/* Create Store */
export const useBedrockRegistration = create(middlewares(bedrockRegistrationStore));
